// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-de-contact-js": () => import("./../../../src/pages/DE/contact.js" /* webpackChunkName: "component---src-pages-de-contact-js" */),
  "component---src-pages-de-index-js": () => import("./../../../src/pages/DE/index.js" /* webpackChunkName: "component---src-pages-de-index-js" */),
  "component---src-pages-de-materials-js": () => import("./../../../src/pages/DE/materials.js" /* webpackChunkName: "component---src-pages-de-materials-js" */),
  "component---src-pages-de-products-js": () => import("./../../../src/pages/DE/products.js" /* webpackChunkName: "component---src-pages-de-products-js" */),
  "component---src-pages-de-specifications-js": () => import("./../../../src/pages/DE/specifications.js" /* webpackChunkName: "component---src-pages-de-specifications-js" */),
  "component---src-pages-de-technology-js": () => import("./../../../src/pages/DE/technology.js" /* webpackChunkName: "component---src-pages-de-technology-js" */),
  "component---src-pages-en-contact-js": () => import("./../../../src/pages/EN/contact.js" /* webpackChunkName: "component---src-pages-en-contact-js" */),
  "component---src-pages-en-index-js": () => import("./../../../src/pages/EN/index.js" /* webpackChunkName: "component---src-pages-en-index-js" */),
  "component---src-pages-en-materials-js": () => import("./../../../src/pages/EN/materials.js" /* webpackChunkName: "component---src-pages-en-materials-js" */),
  "component---src-pages-en-products-js": () => import("./../../../src/pages/EN/products.js" /* webpackChunkName: "component---src-pages-en-products-js" */),
  "component---src-pages-en-specifications-js": () => import("./../../../src/pages/EN/specifications.js" /* webpackChunkName: "component---src-pages-en-specifications-js" */),
  "component---src-pages-en-technology-js": () => import("./../../../src/pages/EN/technology.js" /* webpackChunkName: "component---src-pages-en-technology-js" */),
  "component---src-pages-fr-contact-js": () => import("./../../../src/pages/FR/contact.js" /* webpackChunkName: "component---src-pages-fr-contact-js" */),
  "component---src-pages-fr-index-js": () => import("./../../../src/pages/FR/index.js" /* webpackChunkName: "component---src-pages-fr-index-js" */),
  "component---src-pages-fr-materials-js": () => import("./../../../src/pages/FR/materials.js" /* webpackChunkName: "component---src-pages-fr-materials-js" */),
  "component---src-pages-fr-products-js": () => import("./../../../src/pages/FR/products.js" /* webpackChunkName: "component---src-pages-fr-products-js" */),
  "component---src-pages-fr-specifications-js": () => import("./../../../src/pages/FR/specifications.js" /* webpackChunkName: "component---src-pages-fr-specifications-js" */),
  "component---src-pages-fr-technology-js": () => import("./../../../src/pages/FR/technology.js" /* webpackChunkName: "component---src-pages-fr-technology-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-pl-contact-js": () => import("./../../../src/pages/PL/contact.js" /* webpackChunkName: "component---src-pages-pl-contact-js" */),
  "component---src-pages-pl-index-js": () => import("./../../../src/pages/PL/index.js" /* webpackChunkName: "component---src-pages-pl-index-js" */),
  "component---src-pages-pl-materials-js": () => import("./../../../src/pages/PL/materials.js" /* webpackChunkName: "component---src-pages-pl-materials-js" */),
  "component---src-pages-pl-products-js": () => import("./../../../src/pages/PL/products.js" /* webpackChunkName: "component---src-pages-pl-products-js" */),
  "component---src-pages-pl-specifications-js": () => import("./../../../src/pages/PL/specifications.js" /* webpackChunkName: "component---src-pages-pl-specifications-js" */),
  "component---src-pages-pl-technology-js": () => import("./../../../src/pages/PL/technology.js" /* webpackChunkName: "component---src-pages-pl-technology-js" */),
  "component---src-templates-materials-template-de-js": () => import("./../../../src/templates/Materials-template-DE.js" /* webpackChunkName: "component---src-templates-materials-template-de-js" */),
  "component---src-templates-materials-template-en-js": () => import("./../../../src/templates/Materials-template-EN.js" /* webpackChunkName: "component---src-templates-materials-template-en-js" */),
  "component---src-templates-materials-template-fr-js": () => import("./../../../src/templates/Materials-template-FR.js" /* webpackChunkName: "component---src-templates-materials-template-fr-js" */),
  "component---src-templates-materials-template-pl-js": () => import("./../../../src/templates/Materials-template-PL.js" /* webpackChunkName: "component---src-templates-materials-template-pl-js" */),
  "component---src-templates-product-page-template-de-js": () => import("./../../../src/templates/ProductPage-template-DE.js" /* webpackChunkName: "component---src-templates-product-page-template-de-js" */),
  "component---src-templates-product-page-template-en-js": () => import("./../../../src/templates/ProductPage-template-EN.js" /* webpackChunkName: "component---src-templates-product-page-template-en-js" */),
  "component---src-templates-product-page-template-fr-js": () => import("./../../../src/templates/ProductPage-template-FR.js" /* webpackChunkName: "component---src-templates-product-page-template-fr-js" */),
  "component---src-templates-product-page-template-pl-js": () => import("./../../../src/templates/ProductPage-template-PL.js" /* webpackChunkName: "component---src-templates-product-page-template-pl-js" */),
  "component---src-templates-products-list-template-de-js": () => import("./../../../src/templates/ProductsList-template-DE.js" /* webpackChunkName: "component---src-templates-products-list-template-de-js" */),
  "component---src-templates-products-list-template-en-js": () => import("./../../../src/templates/ProductsList-template-EN.js" /* webpackChunkName: "component---src-templates-products-list-template-en-js" */),
  "component---src-templates-products-list-template-fr-js": () => import("./../../../src/templates/ProductsList-template-FR.js" /* webpackChunkName: "component---src-templates-products-list-template-fr-js" */),
  "component---src-templates-products-list-template-pl-js": () => import("./../../../src/templates/ProductsList-template-PL.js" /* webpackChunkName: "component---src-templates-products-list-template-pl-js" */),
  "component---src-templates-specifications-template-de-js": () => import("./../../../src/templates/Specifications-template-DE.js" /* webpackChunkName: "component---src-templates-specifications-template-de-js" */),
  "component---src-templates-specifications-template-en-js": () => import("./../../../src/templates/Specifications-template-EN.js" /* webpackChunkName: "component---src-templates-specifications-template-en-js" */),
  "component---src-templates-specifications-template-fr-js": () => import("./../../../src/templates/Specifications-template-FR.js" /* webpackChunkName: "component---src-templates-specifications-template-fr-js" */),
  "component---src-templates-specifications-template-pl-js": () => import("./../../../src/templates/Specifications-template-PL.js" /* webpackChunkName: "component---src-templates-specifications-template-pl-js" */),
  "component---src-templates-technology-template-de-js": () => import("./../../../src/templates/Technology-template-DE.js" /* webpackChunkName: "component---src-templates-technology-template-de-js" */),
  "component---src-templates-technology-template-en-js": () => import("./../../../src/templates/Technology-template-EN.js" /* webpackChunkName: "component---src-templates-technology-template-en-js" */),
  "component---src-templates-technology-template-fr-js": () => import("./../../../src/templates/Technology-template-FR.js" /* webpackChunkName: "component---src-templates-technology-template-fr-js" */),
  "component---src-templates-technology-template-pl-js": () => import("./../../../src/templates/Technology-template-PL.js" /* webpackChunkName: "component---src-templates-technology-template-pl-js" */)
}

